import _ from 'lodash';
import UrlUtil from '~@core/util/UrlUtil';
import {BreadcrumbItem} from '~@core/component/Type';
import {trans} from "~@core/util/StringUtil";

export const ROUTES = {
  HOME: '/blog',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  PROFILE: '/profile',
  USER: '/user',
  BLOG: '/blog',
  BLOG_CATEGORY: '/blog/category',
  FAMILY: '/family',
  LOGOUT: '/logout',
  CHANGE_PASSWORD: '/change-password',
};
export const BREADCRUMBS_MAP: Record<string, string | BreadcrumbItem> = {
  '/': 'Trang chủ',
  // '/quote': 'Danh sách bản chào',
  // '/quote/update': {
  //   label(ctx) {
  //     const code = _.get(ctx, 'saleQuote.code');
  //     const segs: string[] = [];
  //     if (code) {
  //       segs.push('Thông tin bản chào:');
  //       segs.push(`${code}`);
  //     } else {
  //       segs.push('Thông tin bản chào');
  //     }
  //     return segs.join(' ');
  //   },
  //   href() {
  //     return UrlUtil.toPathString('/quote/update', UrlUtil.query(['id']))
  //   }
  // },
  '/blog': trans('blog:blog'),
  '/blog/gallery': trans('blog:gallery'),
  '/blog/create': trans('blog:createBlog'),
  '/blog/update': trans('blog:updateBlog'),
  '/blog/category': trans('blog:blogCategory'),
  '/blog/category/create': trans('blog:createBlogCategory'),
  '/blog/category/update': trans('blog:updateBlogCategory'),
  '/family': trans('family:family'),
  '/family/create': trans('family:create'),
  '/family/update': trans('family:update'),
  '/user': trans('user:user'),
  '/user/create': trans('user:create'),
  '/user/update': trans('user:update'),
};

export const MIME_TYPE = {
  IMAGE: 'image/*',
  PDF: 'application/pdf',
  WORD: 'application/msword',
  WORD_X: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL: 'application/vnd.ms-excel',
  EXCEL_X: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: '.csv',
  ZIP: 'application/zip,application/x-zip-compressed,multipart/x-zip',
  ZIP_7z: 'application/x-7z-compressed',
  RAR: 'application/x-rar-compressed',
  OUTLOOK_EMAIL: '.msg'
}