import Http from "~@core/util/Http";
import AuthConstant from "~@app/module/auth/constant/AuthConstant";
import StorageUtil from "~@core/util/StorageUtil";
import AuthInfo from "~@app/module/auth/model/AuthInfo";

class AuthService {
  private path: string;

  private http: Http;

  constructor(path) {
    this.path = path;
    this.http = new Http();
    // this.http.addHeaders({
    // Authorization: this.getAccessToken
    // });
  }

  fetchAccessToken<T>(payload: object) {
    return this.http.callPost<T>(AuthConstant.LOGIN_ACCESS_TOKEN, payload);
  }

  getProfile<T>() {
    return this.http.callGet<T>(AuthConstant.ME, {
      with: ['mainImage']
    }, {
      headers: {
        Authorization: this.getAccessToken
      }
    });
  }

  save(authInfo: AuthInfo) {
    StorageUtil.setObject("auth", authInfo);
  }

  fromLocal(): AuthInfo | null {
    return StorageUtil.getObject("auth") || null;
  }

  logout() {
    StorageUtil.remove('auth');
  }

  isLoggedIn(customerId = undefined) {
    const auth = this.fromLocal();
    return !!auth; // TODO
  }

  getAccessToken() {
    const auth: AuthInfo = StorageUtil.getObject("auth");
    return auth ? `Bearer ${auth.accessToken}` : null;
  }

  revokeAccessToken(token: string) {
    return this.http.callPost(AuthConstant.REVOKE_ACCESS_TOKEN, {token});
  }
}

export default new AuthService("/");