import React, {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import {AuthContextType} from "~@app/component/auth/AuthContextType";
import AuthInfo from "~@app/module/auth/model/AuthInfo";
import AuthService from "~@app/module/auth/service/AuthService";
import {AnyOf} from "~@core/type/Common";
import AccountProfile from "~@app/module/core/model/AccountProfile";
import useApp from "~@app/component/app/UseApp";
import {ROUTES} from "~@app/const/common";

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider = ({children}: PropsWithChildren<Record<string, unknown>>) => {
  const app = useApp();
  const [state, setState] = useState<AnyOf<AuthInfo>>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [me, setMe] = useState<AnyOf<AccountProfile>>();

  useEffect(() => {
    setState(AuthService.fromLocal());
    setIsAuthenticated(AuthService.isLoggedIn());
    setIsLoaded(true);
  }, []);

  const loginSuccess = (authInfo) => {
    setState(authInfo);
    setIsAuthenticated(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const [data] = await AuthService.getProfile<AccountProfile>();
        setMe(data);
      })()
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const log = (event) => {
      const status = event?.details?.response?.status;
      if (status === 401) {
        unAuthenticated();
      }
    };

    window.addEventListener("http", log);
    return () => window.removeEventListener("http", log);
  }, []);

  const getAccessToken = useCallback(async () => state?.accessToken, []);

  const fetchAccessToken = useCallback(async (payload: object) => AuthService.fetchAccessToken(payload), []);

  // const revokeAccessToken = useCallback(async (token) => AuthService.revokeAccessToken(token), []);

  const logout = useCallback(async () => {
    const token = await getAccessToken();
    await unAuthenticated();
    AuthService.logout()
  }, []);

  const unAuthenticated = useCallback(async () => {
    setIsAuthenticated(false);
    setState(null);
    setMe(null);
  }, []);

  const save = useCallback(async (authInfo: AuthInfo) => {
    AuthService.save(authInfo);
    loginSuccess(authInfo);
  }, []);

  const backToPreLoginUrl = useCallback(async () => {
    setTimeout(() => {
      app.navigate(ROUTES.HOME);
    }, 1000);
  }, [])

  const contextValue = useMemo(() => ({
    isAuthenticated,
    logout,
    login: fetchAccessToken,
    save,
    me,
    backToPreLoginUrl
  } as AuthContextType), [isAuthenticated, state, me]);

  return (
    <AuthContext.Provider value={contextValue}>
      {isLoaded ? children : null}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;