import React, {PropsWithChildren} from 'react';
import AuthConstant from '~@app/module/auth/constant/AuthConstant';
import StorageUtil from '~@core/util/StorageUtil';
import useApp from "~@app/component/app/UseApp";
import useAuth from "~@app/component/auth/UseAuth";
import PageLoading from '~@core/component/loading/pageLoading';

export interface AuthRedirectWrapperProps {
  mustAuthenticated?: boolean;
  mustUnauthenticated?: boolean;
  redirectAuthUrl?: string;
  redirectUnauthUrl?: string;
};

export default function AuthRedirectWrapper({
  children,
  mustAuthenticated = true,
  mustUnauthenticated = false,
  redirectAuthUrl = "",
  redirectUnauthUrl = "",
}: PropsWithChildren<AuthRedirectWrapperProps>): JSX.Element {
  const {isAuthenticated} = useAuth();
  const app = useApp();
  if (typeof window !== "undefined") {
    if (mustAuthenticated && !isAuthenticated) {
      const currentLocation = window.location.href;
      StorageUtil.set(AuthConstant.REDIRECT_LOGIN_LOCATION, currentLocation)
      app.redirect(redirectAuthUrl || '/auth/login');
      return <PageLoading/>;
    }
    if (mustUnauthenticated && isAuthenticated) {
      app.redirect(redirectUnauthUrl || '/');
      return <PageLoading/>;
    }
  }

  return <div>{children}</div>;
};